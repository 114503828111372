import { env } from "md-base-tools/env";
import router from '@/router';
import domain from "@/service/domain";
import { GoBack } from "@/utils/tools";
import { getInviteCpDetail, AcceptInviteNewCp } from "@api/index";
import { mdToast } from '@/utils/tools';
export default {
  data() {
    return {
      env: env,
      isFix: 0,
      activeNames: [1],
      showTips: false,
      showSuccess: false,
      inviting: false,
      invite_code: '',
      cpInfo: {
        user_id: '',
        nickname: '',
        icon: ''
      }
    };
  },
  mounted() {
    document.title = '邀请函';
    this.invite_code = this.$route.query.invite_code || '';
    this.getCpInfo();
  },
  methods: {
    // 获取邀请人信息
    async getCpInfo() {
      if (!this.invite_code) return mdToast('未获取到邀请信息');
      const {
        data,
        status,
        message
      } = await getInviteCpDetail({
        invite_code: this.invite_code
      });
      if (status == 0) {
        this.cpInfo = Object.assign(this.cpInfo, data);
      } else {
        mdToast(message);
      }
    },
    // 去活动页面
    goTenth() {
      this.showTips = false;
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/mdactivity/fastsell/merchant_day`,
        webFun: () => {
          router.push({
            path: `/mdactivity/fastsell/merchant_day`
          });
        }
      });
    },
    // 去推荐有礼页面
    goRecommend() {
      this.showTips = false;
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/mdactivity/fastsell/recommend`,
        webFun: () => {
          if (env.isWxApp()) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: '/pages/activePages/recommmend/recommmend'
            });
          } else {
            router.push({
              path: `/mdactivity/fastsell/recommend`
            });
          }
        }
      });
    },
    // 是否吸顶
    handleStickyAction(is_fix) {
      this.isFix = is_fix ? 1 : 0;
    },
    //离开页面
    handleGoBack() {
      GoBack();
    },
    //  接受邀请
    async accecptInvited() {
      const {
        status,
        data,
        message
      } = await AcceptInviteNewCp({
        invite_code: this.invite_code
      });
      if (status == 0 && data) {
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
          this.goInvitedEwm();
        }, 2600);
      } else {
        // 老CP
        if (status == '203705') {
          this.showTips = true;
        } else if (status == '203706') {
          // 已经接受邀请
          mdToast(message || '已接受邀请');
          setTimeout(() => {
            this.goInvitedEwm();
          }, 2600);
        } else {
          mdToast(message);
        }
      }
    },
    goInvitedEwm() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/mdactivity/fastsell/invited_ewm`,
        webFun: () => {
          router.push({
            path: `/mdactivity/fastsell/invited_ewm`
          });
        }
      });
    }
  }
};